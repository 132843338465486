import PageCard from '@boilerplate/components/PageCard';
import { List, ListItemButton, ListItemText, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GitRepositoryModel from '@/entities/gitRepository/GitRepositoryModel';
import NotificationSetting from '@/entities/notificationSetting';

export default function NotificationSettingsPage() {
  const { repositories = [] } = GitRepositoryModel.useGetAllAdvisories();
  const notifyRepositoryIds = NotificationSetting.model.useGetOwnNotificationSettings();
  const deleteNotificationSetting = NotificationSetting.model.useDeleteOwn();
  const createNotificationSetting = NotificationSetting.model.useCreateOwn();
  const [activeRepositories, setActiveRepositories] = useState(new Set(notifyRepositoryIds));
  const { t } = useTranslation();

  useEffect(() => {
    setActiveRepositories(new Set(notifyRepositoryIds));
  }, [notifyRepositoryIds]);

  const handleToggle = (gitRepositoryId: string) => () => {
    setActiveRepositories((prev) => {
      const newActiveRepositories = new Set(Array.from(prev));

      if (prev.has(gitRepositoryId)) {
        newActiveRepositories.delete(gitRepositoryId);

        deleteNotificationSetting(gitRepositoryId).catch(console.error);
      } else {
        newActiveRepositories.add(gitRepositoryId);

        createNotificationSetting(gitRepositoryId).catch(console.error);
      }

      return newActiveRepositories;
    });
  };

  return (
    <PageCard heading={t('entities:notificationSettings')} noBody>
      <List>
        {repositories.map((repository, i) => (
          <ListItemButton key={repository.id} divider={i < repositories.length - 1} onClick={handleToggle(repository.id)}>
            <ListItemText
              id={`switch-${repository.id}`}
              primary={repository.name}
              secondary={repository.branches.map((branch) => branch.name).join(', ')}
            />
            <Switch edge="end" checked={activeRepositories.has(repository.id)} inputProps={{ 'aria-labelledby': `switch-${repository.id}` }} />
          </ListItemButton>
        ))}
      </List>
    </PageCard>
  );
}
