import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetNotificationSettingDocument,
  GetNotificationSettingQuery,
  GetNotificationSettingQueryVariables,
  GetNotificationSettingsDocument,
  GetNotificationSettingsQuery,
  GetNotificationSettingsQueryVariables,
  useGetNotificationSettingQuery,
  useGetNotificationSettingLazyQuery,
  useGetNotificationSettingsQuery,
  useGetNotificationSettingsLazyQuery,

    useGetAllRelatedDataForNotificationSettingQuery,
    useGetAllRelatedDataForNotificationSettingLazyQuery,
    GetAllRelatedDataForNotificationSettingQuery,
    GetAllRelatedDataForNotificationSettingQueryVariables,

  CreateNotificationSettingDocument,
  CreateNotificationSettingMutation,
  CreateNotificationSettingMutationVariables,
  useCreateNotificationSettingMutation,

  DeleteNotificationSettingDocument,
  DeleteNotificationSettingMutation,
  DeleteNotificationSettingMutationVariables,
  useDeleteNotificationSettingMutation,

  UpdateNotificationSettingDocument,
  UpdateNotificationSettingMutation,
  UpdateNotificationSettingMutationVariables,
  useUpdateNotificationSettingMutation,

    useCreatedNotificationSettingSubscription,
    useUpdatedNotificationSettingSubscription,
    useDeletedNotificationSettingSubscription,
    useRestoredNotificationSettingSubscription,
} from '@/graphql';

  type NotificationSettingCollection = Dictionary<NonNullable<GetNotificationSettingsQuery['notificationSettings']['items']>[number]>;

const NotificationSettingBaseModel = {
  get: (options: Omit<QueryOptions<GetNotificationSettingQueryVariables, GetNotificationSettingQuery>, 'query'>) => {
    return apolloClient.query<GetNotificationSettingQuery, GetNotificationSettingQueryVariables>({
      ...options,
      query: GetNotificationSettingDocument,
    })
    .then(({ data }) => data.notificationSetting);
  },

  useGet: useGetNotificationSettingQuery,

  getAll: (options?: Omit<QueryOptions<GetNotificationSettingsQueryVariables, GetNotificationSettingsQuery>, 'query'>) => {
    return apolloClient
      .query<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>({
        ...options,
        query: GetNotificationSettingsDocument
      })
      .then(({ data }) => data.notificationSettings.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>) => {
    const hookResult = useGetNotificationSettingsQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.notificationSettings?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForNotificationSettingQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForNotificationSettingQuery, GetAllRelatedDataForNotificationSettingQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForNotificationSettingQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetNotificationSettingLazyQuery,

  useGetAllLazy: useGetNotificationSettingsLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForNotificationSettingLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateNotificationSettingMutation, CreateNotificationSettingMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateNotificationSettingMutation, CreateNotificationSettingMutationVariables>({
      ...options,
      mutation: CreateNotificationSettingDocument,
    });
  },

  useCreate: useCreateNotificationSettingMutation,

  update: (options: Omit<MutationOptions<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>({
      ...options,
      mutation: UpdateNotificationSettingDocument,
    });
  },

  useUpdate: useUpdateNotificationSettingMutation,

  delete: (options: Omit<MutationOptions<DeleteNotificationSettingMutation, DeleteNotificationSettingMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteNotificationSettingMutation, DeleteNotificationSettingMutationVariables>({
      ...options,
      mutation: DeleteNotificationSettingDocument,
    });
  },

  useDelete: useDeleteNotificationSettingMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>) => {
      const [collection, setCollection] = useState<NotificationSettingCollection>({});

      const { items, loading, error, refetch } = NotificationSettingBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedNotificationSettingSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdNotificationSetting?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdNotificationSetting.id]: data.createdNotificationSetting,
            }));
          }
        },
      });

      useUpdatedNotificationSettingSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedNotificationSetting?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedNotificationSetting.id]: data.updatedNotificationSetting,
            }));
          }
        },
      });

      useDeletedNotificationSettingSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedNotificationSetting?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedNotificationSetting.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredNotificationSettingSubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredNotificationSetting?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredNotificationSetting.id]: data.restoredNotificationSetting,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default NotificationSettingBaseModel;
