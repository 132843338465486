import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetGitRepositoryDocument,
  GetGitRepositoryQuery,
  GetGitRepositoryQueryVariables,
  GetGitRepositoriesDocument,
  GetGitRepositoriesQuery,
  GetGitRepositoriesQueryVariables,
  useGetGitRepositoryQuery,
  useGetGitRepositoryLazyQuery,
  useGetGitRepositoriesQuery,
  useGetGitRepositoriesLazyQuery,

    useGetAllRelatedDataForGitRepositoryQuery,
    useGetAllRelatedDataForGitRepositoryLazyQuery,
    GetAllRelatedDataForGitRepositoryQuery,
    GetAllRelatedDataForGitRepositoryQueryVariables,

  CreateGitRepositoryDocument,
  CreateGitRepositoryMutation,
  CreateGitRepositoryMutationVariables,
  useCreateGitRepositoryMutation,

  DeleteGitRepositoryDocument,
  DeleteGitRepositoryMutation,
  DeleteGitRepositoryMutationVariables,
  useDeleteGitRepositoryMutation,

  UpdateGitRepositoryDocument,
  UpdateGitRepositoryMutation,
  UpdateGitRepositoryMutationVariables,
  useUpdateGitRepositoryMutation,

    useCreatedGitRepositorySubscription,
    useUpdatedGitRepositorySubscription,
    useDeletedGitRepositorySubscription,
    useRestoredGitRepositorySubscription,
} from '@/graphql';

  type GitRepositoryCollection = Dictionary<NonNullable<GetGitRepositoriesQuery['gitRepositories']['items']>[number]>;

const GitRepositoryBaseModel = {
  get: (options: Omit<QueryOptions<GetGitRepositoryQueryVariables, GetGitRepositoryQuery>, 'query'>) => {
    return apolloClient.query<GetGitRepositoryQuery, GetGitRepositoryQueryVariables>({
      ...options,
      query: GetGitRepositoryDocument,
    })
    .then(({ data }) => data.gitRepository);
  },

  useGet: useGetGitRepositoryQuery,

  getAll: (options?: Omit<QueryOptions<GetGitRepositoriesQueryVariables, GetGitRepositoriesQuery>, 'query'>) => {
    return apolloClient
      .query<GetGitRepositoriesQuery, GetGitRepositoriesQueryVariables>({
        ...options,
        query: GetGitRepositoriesDocument
      })
      .then(({ data }) => data.gitRepositories.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetGitRepositoriesQuery, GetGitRepositoriesQueryVariables>) => {
    const hookResult = useGetGitRepositoriesQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.gitRepositories?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForGitRepositoryQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForGitRepositoryQuery, GetAllRelatedDataForGitRepositoryQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForGitRepositoryQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetGitRepositoryLazyQuery,

  useGetAllLazy: useGetGitRepositoriesLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForGitRepositoryLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateGitRepositoryMutation, CreateGitRepositoryMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateGitRepositoryMutation, CreateGitRepositoryMutationVariables>({
      ...options,
      mutation: CreateGitRepositoryDocument,
    });
  },

  useCreate: useCreateGitRepositoryMutation,

  update: (options: Omit<MutationOptions<UpdateGitRepositoryMutation, UpdateGitRepositoryMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateGitRepositoryMutation, UpdateGitRepositoryMutationVariables>({
      ...options,
      mutation: UpdateGitRepositoryDocument,
    });
  },

  useUpdate: useUpdateGitRepositoryMutation,

  delete: (options: Omit<MutationOptions<DeleteGitRepositoryMutation, DeleteGitRepositoryMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteGitRepositoryMutation, DeleteGitRepositoryMutationVariables>({
      ...options,
      mutation: DeleteGitRepositoryDocument,
    });
  },

  useDelete: useDeleteGitRepositoryMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetGitRepositoriesQuery, GetGitRepositoriesQueryVariables>) => {
      const [collection, setCollection] = useState<GitRepositoryCollection>({});

      const { items, loading, error, refetch } = GitRepositoryBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedGitRepositorySubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdGitRepository?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdGitRepository.id]: data.createdGitRepository,
            }));
          }
        },
      });

      useUpdatedGitRepositorySubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedGitRepository?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedGitRepository.id]: data.updatedGitRepository,
            }));
          }
        },
      });

      useDeletedGitRepositorySubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedGitRepository?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedGitRepository.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredGitRepositorySubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredGitRepository?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredGitRepository.id]: data.restoredGitRepository,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default GitRepositoryBaseModel;
