import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        name: {
          type: String,
          form: true,
          view: true,
        },
        branches: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'branch',
            displayField: 'name',
          },
          optional: true,
          form: false,
          view: true,
        },
        'branches.$': {
          type: String,
          optional: true,
        },
        notificationSettings: {
          type: Array,
          relation: {
            name: 'OneToMany',
            type: 'OneToMany',
            model: 'notificationSetting',
            displayField: 'id',
          },
          optional: true,
          form: false,
          view: true,
        },
        'notificationSettings.$': {
          type: String,
          optional: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:gitRepository.id', 'entityFields:default.id'], '') || undefined,
        name: t(['entityFields:gitRepository.name', 'entityFields:default.name'], '') || undefined,
        branches: t(['entityFields:gitRepository.branch', 'entityFields:default.branch'], '') || undefined,
        notificationSettings: t(['entityFields:gitRepository.notification_setting', 'entityFields:default.notification_setting'], '') || undefined,
        createdAt: t(['entityFields:gitRepository.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:gitRepository.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:gitRepository.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
});

export default schema;