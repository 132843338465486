import { Typography } from '@mui/material';
import React from 'react';

export default function AuthLayoutRight() {
  return (
    <>
      <Typography variant="h2">Audit</Typography>

      <ul>
        <li>See vulnerability audits of dependencies</li>
        <li>Get notified of found vulnerabilities</li>
      </ul>
    </>
  );
}
