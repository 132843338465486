import usePromise from '@boilerplate/lib/usePromise';
import { Replay as ReloadIcon } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import Notistack from '@/lib/notistack';

export default function Reload({
  url,
  refetch,
  ...props
}: { url: string; refetch: () => Promise<unknown | void> } & Omit<
  LoadingButtonProps,
  'loading' | 'onClick' | 'loadingPosition' | 'startIcon'
>) {
  const { t } = useTranslation();
  const { loading, exec } = usePromise(
    async () => {
      await axios.post(url).catch((err) => {
        console.error(err);
        Notistack.toast(err);
      });

      await refetch();
    },
    [url],
    { lazy: true }
  );

  return (
    <LoadingButton variant="outlined" {...props} loading={loading} onClick={exec} loadingPosition="start" startIcon={<ReloadIcon />}>
      {t('strings:rerun')}
    </LoadingButton>
  );
}
