import PageCard from '@boilerplate/components/PageCard';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GitRepositoryModel from '@/entities/gitRepository/GitRepositoryModel';

import Reload from './Reload';

export default function Overview() {
  const navigate = useNavigate();
  const { loading, repositories: allRepositories, refetch } = GitRepositoryModel.useGetAllAdvisories();
  const [search, setSearch] = useState('');

  const repositories = useMemo(() => {
    if (!search) {
      return allRepositories;
    }

    const preparedSearch = new RegExp(search.toLowerCase());

    return allRepositories.filter((repository) => repository.name.toLowerCase().search(preparedSearch) !== -1);
  }, [search, allRepositories]);

  const handleClick = useCallback(
    (branchId) => () => {
      navigate(`/branches/${branchId}`);
    },
    [navigate]
  );

  return (
    <PageCard
      heading="Security advisories"
      headingRight={
        <>
          {loading && <CircularProgress size={20} />}
          <Reload url="/scan/audit" refetch={refetch} />
          <TextField value={search} onChange={(e) => setSearch(e.target.value)} label="Search" size="small" autoFocus />
        </>
      }
      noBody
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Repository</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell>Last checked</TableCell>
              <TableCell style={{ width: 100, textAlign: 'right' }}>Critical</TableCell>
              <TableCell style={{ width: 100, textAlign: 'right' }}>High</TableCell>
              <TableCell style={{ width: 100, textAlign: 'right' }}>Moderate</TableCell>
              <TableCell style={{ width: 100, textAlign: 'right' }}>Low</TableCell>
              <TableCell style={{ width: 100, textAlign: 'right' }}>Unknown</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {repositories.map((repository) =>
              repository.branches.map((branch, index) => (
                <TableRow key={branch.id} hover onClick={handleClick(branch.id)}>
                  <TableCell>{index === 0 && repository.name}</TableCell>

                  <TableCell>{branch.name}</TableCell>

                  <TableCell>{!!branch.lastScanUpdatedAt && formatDistanceToNow(branch.lastScanUpdatedAt, { addSuffix: true })}</TableCell>

                  <TableCell style={{ fontWeight: 'bold', textAlign: 'right', color: 'red' }}>{branch.totals.critical || ''}</TableCell>

                  <TableCell style={{ fontWeight: 'bold', textAlign: 'right', color: '#ff3c00' }}>{branch.totals.high || ''}</TableCell>

                  <TableCell style={{ fontWeight: 'bold', textAlign: 'right', color: 'orange' }}>{branch.totals.moderate || ''}</TableCell>

                  <TableCell style={{ fontWeight: 'bold', textAlign: 'right', color: '#888' }}>{branch.totals.low || ''}</TableCell>

                  <TableCell style={{ fontWeight: 'bold', textAlign: 'right', color: 'purple' }}>{branch.totals.unknown || ''}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </PageCard>
  );
}
