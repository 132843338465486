import SimpleSchema from '@boilerplate/lib/simpleSchema';
import { getI18n } from 'react-i18next';

const schema = new SimpleSchema({
        id: {
          type: String,
          optional: true,
          form: false,
          view: true,
        },
        user: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'user',
            displayField: 'name',
          },
          form: true,
          view: true,
        },
        gitRepository: {
          type: String,
          relation: {
            name: 'ManyToOne',
            type: 'ManyToOne',
            model: 'gitRepository',
            displayField: 'name',
          },
          form: true,
          view: true,
        },
        createdAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        updatedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
        deletedAt: {
          type: Date,
          optional: true,
          form: false,
          view: true,
        },
});

const { t } = getI18n();
schema.labels({
        id: t(['entityFields:notificationSetting.id', 'entityFields:default.id'], '') || undefined,
        user: t(['entityFields:notificationSetting.user', 'entityFields:default.user'], '') || undefined,
        gitRepository: t(['entityFields:notificationSetting.git_repository', 'entityFields:default.git_repository'], '') || undefined,
        createdAt: t(['entityFields:notificationSetting.created_at', 'entityFields:default.created_at'], '') || undefined,
        updatedAt: t(['entityFields:notificationSetting.updated_at', 'entityFields:default.updated_at'], '') || undefined,
        deletedAt: t(['entityFields:notificationSetting.deleted_at', 'entityFields:default.deleted_at'], '') || undefined,
});

export default schema;