import { GetAllRepositoryAdvisoriesQuery, useGetAllRepositoryAdvisoriesQuery } from '@/graphql';
import GitRepositoryBaseModel from '@/_base/gitRepository/GitRepositoryBaseModel';
import { compareDesc } from 'date-fns';
import { useMemo } from 'react';

type GroupedBySeverity<T> = {
  critical: T;
  high: T;
  moderate: T;
  low: T;
  unknown: T;
};

type RepositoryType = NonNullable<GetAllRepositoryAdvisoriesQuery['gitRepositories']['items']>[number];
type BranchType = NonNullable<RepositoryType['branches']['items']>[number];
type ScanType = NonNullable<BranchType['scans']['items']>[number];
type AdvisoryType = NonNullable<ScanType['advisories']['items']>[number];

type RepositoryAdvisoriesType = Omit<RepositoryType, 'branches'> & {
  branches: (Omit<BranchType, 'scans'> & {
    totals: GroupedBySeverity<number>;
    lastScanUpdatedAt?: Date;
    scans: (Omit<ScanType, 'advisories'> & {
      advisories: GroupedBySeverity<AdvisoryType[]>;
    })[];
  })[];
};

// TODO merge with RepositoryModel
const GitRepositoryModel = {
  ...GitRepositoryBaseModel,

  useGetAllAdvisories: () => {
    const hookResult = useGetAllRepositoryAdvisoriesQuery({ fetchPolicy: 'cache-and-network' });

    const repositoriesData = useMemo(() => {
      return hookResult.data?.gitRepositories?.items ?? [];
    }, [hookResult.data]);

    // List of repositories, with advisories grouped by severity.
    const repositories = useMemo(() => {
      return repositoriesData.map((repositoryItem) => {
        const repository: RepositoryAdvisoriesType = {
          ...repositoryItem,
          branches: [],
        };

        const brancheItems = repositoryItem.branches.items || [];

        brancheItems.forEach((branchItem) => {
          const scanItems = branchItem.scans.items || [];

          const scans = scanItems.map((scanItem) => {
            const advisoryItems = scanItem.advisories.items || [];

            return {
              ...scanItem,
              advisories: {
                critical: advisoryItems.filter((advisory) => advisory.severity?.toLowerCase() === 'critical'),
                high: advisoryItems.filter((advisory) => advisory.severity?.toLowerCase() === 'high'),
                moderate: advisoryItems.filter((advisory) => advisory.severity?.toLowerCase() === 'moderate'),
                low: advisoryItems.filter((advisory) => advisory.severity?.toLowerCase() === 'low'),
                unknown: advisoryItems.filter(
                  (advisory) => !['critical', 'high', 'moderate', 'low'].includes(advisory.severity?.toLowerCase() || '')
                ),
              },
            };
          });

          const dates = scans.filter((scan) => scan.updatedAt).map((scan) => new Date(scan.updatedAt));
          const lastScanUpdatedAt = dates.length ? dates.sort(compareDesc)[0] : undefined;

          repository.branches.push({
            ...branchItem,
            lastScanUpdatedAt,
            scans,
            totals: {
              critical: scans.reduce((count, scan) => count + scan.advisories.critical.length, 0),
              high: scans.reduce((count, scan) => count + scan.advisories.high.length, 0),
              moderate: scans.reduce((count, scan) => count + scan.advisories.moderate.length, 0),
              low: scans.reduce((count, scan) => count + scan.advisories.low.length, 0),
              unknown: scans.reduce((count, scan) => count + scan.advisories.unknown.length, 0),
            },
          });
        });

        return repository;
      });
    }, [repositoriesData]);

    // Separate list of all branches (useful for the BranchPage).
    const branches = useMemo(() => {
      return repositories.flatMap((repository) =>
        repository.branches.map((branch) => ({
          ...branch,
          repository: {
            id: repository.id,
            name: repository.name,
          },
        }))
      );
    }, [repositories]);

    return {
      ...hookResult,
      repositories,
      branches,
    };
  },
};

export default GitRepositoryModel;
