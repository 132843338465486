import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import BranchPage from '@/components/app/Branch/BranchPage';
import ContainedAppLayout from '@/components/app/Layout/ContainedAppLayout';
import NotificationSettingsPage from '@/components/app/Notification/NotificationSettingsPage';
import Overview from '@/components/app/Overview';
import ProfilePage from '@/components/app/Profile/ProfilePage';
import CompleteRegistrationPage from '@/components/auth/CompleteRegistrationPage';
import ConfirmationPage from '@/components/auth/ConfirmationPage';
import PageNotFound from '@/components/shared/PageNotFound';

import { Public, RequireAuth, RequireTenant } from './lib';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <ContainedAppLayout>
      <Routes location={location}>
        <Route
          key="home"
          path="/"
          element={
            <RequireAuth>
              <Overview />
            </RequireAuth>
          }
        />
        <Route
          path="/branches/:branchId"
          element={
            <RequireAuth>
              <BranchPage />
            </RequireAuth>
          }
        />
        <Route
          path="/notification-settings"
          element={
            <RequireAuth>
              <NotificationSettingsPage />
            </RequireAuth>
          }
        />
        <Route key="completeRegistration" path="completeRegistration" element={<CompleteRegistrationPage />} />
        <Route
          key="profile"
          path="/profile/*"
          element={
            <RequireTenant>
              <ProfilePage />
            </RequireTenant>
          }
        />
        <Route
          key="confirm"
          path="/confirm/:confirmationCode"
          element={
            <Public>
              <ConfirmationPage />
            </Public>
          }
        />
        <Route
          key="error"
          path="*"
          element={
            <Public>
              <PageNotFound />
            </Public>
          }
        />
      </Routes>
    </ContainedAppLayout>
  );
}
