import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import GitRepositoryBaseOrderFormSchema from '@/_base/gitRepository/GitRepositoryBaseOrderFormSchema';
import GitRepositoryBaseSimpleSchema from '@/_base/gitRepository/GitRepositoryBaseSimpleSchema';
import GitRepositoryBaseTableSchema from '@/_base/gitRepository/GitRepositoryBaseTableSchema';
import GitRepositoryBaseInsertForm from '@/_base/gitRepository/components/GitRepositoryBaseInsertForm';
import GitRepositoryBaseUpdateForm from '@/_base/gitRepository/components/GitRepositoryBaseUpdateForm';
import GitRepositoryBaseTable from '@/_base/gitRepository/components/GitRepositoryBaseTable';
import GitRepositoryBaseView from '@/_base/gitRepository/components/GitRepositoryBaseView';
import GitRepositoryModel from './GitRepositoryModel';

const GitRepositoryEntity: Entity<typeof GitRepositoryModel> = {
  name: 'git_repository',

  simpleSchema: GitRepositoryBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(GitRepositoryBaseSimpleSchema),
    orderSchema: [...GitRepositoryBaseOrderFormSchema],
    component: GitRepositoryBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(GitRepositoryBaseSimpleSchema),
    orderSchema: [...GitRepositoryBaseOrderFormSchema],
    component: GitRepositoryBaseUpdateForm,
  },

  table: {
    schema: GitRepositoryBaseTableSchema,
    component: GitRepositoryBaseTable,
  },

  view: {
    component: GitRepositoryBaseView,
  },

  model: GitRepositoryModel,

  routes: {},

  settings: {
    displayField: 'name',
  },
};

export default GitRepositoryEntity;
