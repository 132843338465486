import advisory from './advisory.json';
import branch from './branch.json';
import content from './content.json';
import contentFill from './contentFill.json';
import defaultTranslations from './default.json';
import errorLog from './errorLog.json';
import eventLog from './eventLog.json';
import gitRepository from './gitRepository.json';
import job from './job.json';
import mailLog from './mailLog.json';
import mailTemplate from './mailTemplate.json';
import mailTemplateFill from './mailTemplateFill.json';
import notificationSetting from './notificationSetting.json';
import permission from './permission.json';
import resetPassword from './resetPassword.json';
import role from './role.json';
import scan from './scan.json';
import scanFile from './scanFile.json';
import tenant from './tenant.json';
import user from './user.json';
import userTenantRole from './userTenantRole.json';

export default {
  advisory,
  branch,
  content,
  contentFill,
  default: defaultTranslations,
  errorLog,
  eventLog,
  gitRepository,
  job,
  mailLog,
  mailTemplate,
  mailTemplateFill,
  notificationSetting,
  permission,
  resetPassword,
  role,
  scan,
  scanFile,
  tenant,
  user,
  userTenantRole,
};
