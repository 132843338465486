import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import RoleBaseOrderFormSchema from '@/_base/role/RoleBaseOrderFormSchema';
import RoleBaseModel from '@/_base/role/RoleBaseModel';
import RoleBaseSimpleSchema from '@/_base/role/RoleBaseSimpleSchema';
import RoleBaseTableSchema from '@/_base/role/RoleBaseTableSchema';
import RoleBaseInsertForm from '@/_base/role/components/RoleBaseInsertForm';
import RoleBaseUpdateForm from '@/_base/role/components/RoleBaseUpdateForm';
import RoleBaseTable from '@/_base/role/components/RoleBaseTable';
import RoleBaseView from '@/_base/role/components/RoleBaseView';

RoleBaseSimpleSchema.extend({
  permissions: {
    form: {
      disabled: true,
    },
  },
  machineName: {
    form: {
      disabled: true,
    },
  },
});

const RoleEntity: Entity<typeof RoleBaseModel> = {
  name: 'role',

  simpleSchema: RoleBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(RoleBaseSimpleSchema),
    orderSchema: [...RoleBaseOrderFormSchema],
    component: RoleBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(RoleBaseSimpleSchema),
    orderSchema: [...RoleBaseOrderFormSchema],
    component: RoleBaseUpdateForm,
  },

  table: {
    schema: RoleBaseTableSchema,
    component: RoleBaseTable,
  },

  view: {
    component: RoleBaseView,
  },

  model: RoleBaseModel,

  routes: {},

  settings: {
    displayField: 'displayName',
  },
};

export default RoleEntity;
