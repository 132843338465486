import {
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';

import GitRepositoryEntity from '@/entities/gitRepository';

import Reload from '../Reload';

const severities = ['critical', 'high', 'moderate', 'low', 'unknown'] as const;

type AdvisoryType = {
  id: string;
  package: string;
  parentPackage?: string | null;
  patchedVersion?: string | null;
  severity?: string | null;
  title: string;
  version: string;
};

type Refetch = ReturnType<typeof GitRepositoryEntity.model.useGetAllAdvisories>['refetch'];

type ScanCardProps = {
  refetch: Refetch;
  scan: {
    id: string;
    directory: string;
    type: string;
    updatedAt?: string;
    advisories: {
      critical: AdvisoryType[];
      high: AdvisoryType[];
      moderate: AdvisoryType[];
      low: AdvisoryType[];
      unknown: AdvisoryType[];
    };
  };
};

const useStyles = makeStyles()(() => ({
  maxHeight: {
    maxHeight: '22rem',
    overflow: 'auto',
  },
  chip: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '&>span': {
      alignItems: 'center',
      display: 'flex',
    },
  },
}));

const parsePNPMPath = (path: string) =>
  path
    .trim()
    .split(',')
    .map((line) => line.split(' > '));

const Tree = ({ advisory, scan }: { advisory: AdvisoryType; scan: ScanCardProps['scan'] }) => {
  const { classes } = useStyles();

  const parsedTree = useMemo(
    () => (scan.type === 'pnpm' && advisory.parentPackage ? parsePNPMPath(advisory.parentPackage) : null),
    [scan.type, advisory.parentPackage]
  );

  if (parsedTree) {
    const slice = parsedTree.slice(0, 1000);

    return (
      <Stack className={classes.maxHeight} alignContent="flex-start" justifyContent="flex-start" gap={1}>
        {slice.length < parsedTree.length && (
          <Typography variant="caption">
            Rows are truncated to {slice.length} items (from {parsedTree.length})
          </Typography>
        )}
        {slice.map((entry, index) => (
          <Chip
            key={index}
            className={classes.chip}
            label={entry.map((packageEntry) => (
              <React.Fragment key={packageEntry}>
                <ChevronRightIcon />
                {packageEntry}
              </React.Fragment>
            ))}
          />
        ))}
      </Stack>
    );
  }

  return <Typography className={classes.maxHeight}>{advisory.parentPackage}</Typography>;
};

export default function ScanCard({ scan, refetch }: ScanCardProps) {
  const [opened, setOpened] = useState(new Set());

  const toggleOpened = useCallback(
    (scanId: string) => () => {
      setOpened((prev) => {
        const newOpened = new Set(prev);

        if (newOpened.has(scanId)) {
          newOpened.delete(scanId);
        } else {
          newOpened.add(scanId);
        }

        return newOpened;
      });
    },
    []
  );

  return (
    <Card>
      <CardContent>
        <Grid container mb={2}>
          <Typography variant="h5" flex="1">
            {scan.directory}
          </Typography>

          <Grid item>
            <Stack direction="row" spacing={1} style={{ overflowX: 'auto' }}>
              <Chip label={scan.type} variant="outlined" />
              {!!scan.updatedAt && <Chip label={formatDistanceToNow(new Date(scan.updatedAt), { addSuffix: true })} variant="outlined" />}
              <Reload url={`/scan/${scan.id}/audit`} refetch={refetch} size="small" />
            </Stack>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={1}>
          {severities.map((severity) => (
            <Chip key={severity} label={`${severity}: ${scan.advisories[severity].length}`} />
          ))}
        </Stack>

        <Button onClick={toggleOpened(scan.id)} variant="text" sx={{ marginTop: 2 }}>
          {opened.has(scan.id) ? 'View less' : 'View more'}
        </Button>
      </CardContent>

      <Collapse in={opened.has(scan.id)}>
        {severities.map(
          (severity) =>
            !!scan.advisories[severity].length && (
              <React.Fragment key={severity}>
                <CardContent>
                  <Typography variant="h5">{severity}</Typography>
                </CardContent>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Package</TableCell>
                        <TableCell>Advisory</TableCell>
                        <TableCell>Version</TableCell>
                        <TableCell>Patch</TableCell>
                        <TableCell>Tree</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {scan.advisories[severity].map((advisory) => (
                        <TableRow key={advisory.id}>
                          <TableCell>{advisory.package}</TableCell>
                          <TableCell>{advisory.title}</TableCell>
                          <TableCell>{advisory.version}</TableCell>
                          <TableCell>{advisory.patchedVersion}</TableCell>
                          <TableCell>
                            <Tree advisory={advisory} scan={scan} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            )
        )}
      </Collapse>
    </Card>
  );
}
