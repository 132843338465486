import customActions from '@boilerplate/lib/customActions';
import TableSchema from '@boilerplate/types/tableSchema';
import { getI18n } from 'react-i18next';

import { CellRenderers, ColumnFilters } from '@/components/entity';

const { t } = getI18n();

const actions = customActions(CellRenderers.ActionButtons, t);

const NotificationSettingBaseTableSchemaWithoutActions: TableSchema[] = [
      {
          field: 'id',
        label: t(['entityFields:notificationSetting.id', 'entityFields:default.id'], 'id') || undefined,
          cell: CellRenderers['IdRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['IdFilter'],
            sortable: true,
      },
      {
          field: 'user',
        label: t(['entityFields:notificationSetting.user', 'entityFields:default.user'], 'user') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'gitRepository',
        label: t(['entityFields:notificationSetting.git_repository', 'entityFields:default.git_repository'], 'gitRepository') || undefined,
          cell: CellRenderers.AutoRenderer,
          filter: ColumnFilters['StringFilter'],
                  sortable: false,
      },
      {
          field: 'createdAt',
        label: t(['entityFields:notificationSetting.created_at', 'entityFields:default.created_at'], 'createdAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
      {
          field: 'updatedAt',
        label: t(['entityFields:notificationSetting.updated_at', 'entityFields:default.updated_at'], 'updatedAt') || undefined,
          cell: CellRenderers['DateTimeRenderer'] ?? CellRenderers.AutoRenderer,
          filter: ColumnFilters['DateTimeFilter'],
            sortable: true,
        optional: true,
      },
];

const NotificationSettingBaseTableSchema: TableSchema[] = [
  ...NotificationSettingBaseTableSchemaWithoutActions,
  actions,
];

export { actions, NotificationSettingBaseTableSchemaWithoutActions };
export default NotificationSettingBaseTableSchema;
