import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';

import NotificationSettingBaseOrderFormSchema from '@/_base/notificationSetting/NotificationSettingBaseOrderFormSchema';
import NotificationSettingBaseModel from '@/_base/notificationSetting/NotificationSettingBaseModel';
import NotificationSettingBaseSimpleSchema from '@/_base/notificationSetting/NotificationSettingBaseSimpleSchema';
import NotificationSettingBaseTableSchema from '@/_base/notificationSetting/NotificationSettingBaseTableSchema';
import NotificationSettingBaseInsertForm from '@/_base/notificationSetting/components/NotificationSettingBaseInsertForm';
import NotificationSettingBaseUpdateForm from '@/_base/notificationSetting/components/NotificationSettingBaseUpdateForm';
import NotificationSettingBaseTable from '@/_base/notificationSetting/components/NotificationSettingBaseTable';
import NotificationSettingBaseView from '@/_base/notificationSetting/components/NotificationSettingBaseView';

const NotificationSettingEntity: Entity<typeof NotificationSettingBaseModel> = {
  name: 'notification_setting',

  simpleSchema: NotificationSettingBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(NotificationSettingBaseSimpleSchema),
    orderSchema: [...NotificationSettingBaseOrderFormSchema],
    component: NotificationSettingBaseInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(NotificationSettingBaseSimpleSchema),
    orderSchema: [...NotificationSettingBaseOrderFormSchema],
    component: NotificationSettingBaseUpdateForm,
  },

  table: {
    schema: NotificationSettingBaseTableSchema,
    component: NotificationSettingBaseTable,
  },

  view: {
    component: NotificationSettingBaseView,
  },

  model: NotificationSettingBaseModel,

  routes: {},

  settings: {
    displayField: 'id',
  },
};

export default NotificationSettingEntity;
