import PageHeading from '@boilerplate/components/PageHeading';
import { CircularProgress, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import GitRepositoryModel from '@/entities/gitRepository/GitRepositoryModel';

import ScanCard from './ScanCard';

export default function BranchPage() {
  const { branchId } = useParams<{ branchId?: string }>();
  const { loading, branches, refetch } = GitRepositoryModel.useGetAllAdvisories();

  const branch = useMemo(() => branches.find((branchItem) => branchItem.id === branchId), [branchId, branches]);

  if (!branch) {
    return null;
  }

  return (
    <>
      <PageHeading title={`${branch.repository.name} - ${branch.name}`} right={loading && <CircularProgress size={20} />} arrowBack />

      <Stack spacing={2}>
        {branch.scans.map((scan) => (
          <ScanCard key={scan.id} scan={scan} refetch={refetch} />
        ))}
      </Stack>
    </>
  );
}
